<script>
  import {
    Block,
    f7ready,
    Link,
    List,
    ListItem,
    Navbar,
    NavRight,
    Page,
    Popover,
    Stepper
  } from "framework7-svelte";
  import HomeButton from "../components/home-button.svelte";
  import SunriseBackground from "../components/sunrise-background.svelte";
  import { browseURL, showInstallToast } from "../js/app.ts";
  import tr from "../js/i18n.ts";
  import { getWelcomeMessage } from "../js/notifications.ts";
  import {
    navigateSession,
    ROUTE_SETTINGS,
    ROUTE_TIMER
  } from "../js/routes.ts";
  import {
    getStoredValue,
    setStoredValue,
    StoreValue
  } from "../js/settings.ts";

  // Router component will receive f7router prop with current Router instance
  export let f7router;

  var lastSelectedTime = getStoredValue(StoreValue.favorite_time, 5); // default to 5m

  // get welcome mnessage
  const [welcomeTitle, welcomeMsg] = getWelcomeMessage(new Date().getHours());

  function start() {
    startCustomTimeSession(lastSelectedTime);
  }

  function startCustomTimeSession(minutes) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);
    navigateSession(f7router, minutes);
  }

  function updateCustomTimeSession(minutes) {
    if (minutes <= 0 || minutes > 600)
      throw Error(`Invalid minutes range ${minutes}`);
    setStoredValue(StoreValue.favorite_time, minutes);
    lastSelectedTime = minutes;
  }

  const formatMinutes = (min) =>
    `${min} ${tr("common.minute", { count: min }).toLowerCase()}`;

  const browseSupportPage = () =>
    browseURL(
      "https://coherence-app.com/support-us?utm_source=app&utm_content=home_heart_link"
    );

  const browseHelpPage = () =>
    browseURL(
      "https://coherence-app.com/information/tag/help?utm_source=app&utm_content=home_help_link"
    );

  const browseTimer = () =>
    f7router.navigate(`${ROUTE_TIMER}${lastSelectedTime}`, {
      props: {
        bgVideoUrl:
          "https://cdn.pixabay.com/video/2021/04/15/71122-537102350_large.mp4"
        // bgImageUrl: "https://images.unsplash.com/photo-1473448912268-2022ce9509d8"
      }
    });

  let installTimer;
  let installToast;

  const onPageAfterIn = () => {
    f7ready((f7) => {
      // animate welcome
      f7.$("h1.fade").animate(
        { opacity: 1 },
        { duration: 1200, easing: "swing" }
      );
      setTimeout(() => {
        f7.$("h2.fade").animate(
          { opacity: 1 },
          { duration: 600, easing: "swing" }
        );
      }, 600); // delay H2 to make it pretty

      // offer user to install if needed
      installTimer = setTimeout(() => {
        installToast = showInstallToast(f7);
      }, 3000);
    });
  };

  function onPageBeforeOut() {
    // should close toast as well
    if (installTimer) {
      clearTimeout(installTimer);
      installTimer = null;
    }
    if (installToast) {
      installToast.close();
      installToast = null;
    }
  }
</script>

<Page
  pageContent={false}
  name="home"
  class="no-default-bg"
  onPageAfterIn={onPageAfterIn}
  onPageBeforeOut={onPageBeforeOut}>
  <Navbar transparent sliding={false}>
    <NavRight>
      <Link iconMaterial="help" onClick={browseHelpPage}></Link>
      <!-- <Link iconMaterial="share" onClick={shareApp}></Link> -->
      <Link iconMaterial="settings" href={ROUTE_SETTINGS}></Link>
    </NavRight>
  </Navbar>

  <SunriseBackground />

  <div class="container">
    <div class="messages-container">
      <Block textColor="white">
        <h1 class="fade" style="opacity: 0;">
          {welcomeTitle},
        </h1>
        <h2 class="fade" style="opacity: 0;">
          I have <Link popoverOpen=".popover-menu" style="color: aqua"
            >{formatMinutes(lastSelectedTime)} ▼</Link> to...
        </h2>
        <Popover
          class="popover-menu"
          closeByOutsideClick={true}
          closeOnEscape={true}
          style="width: 16.5em; opacity 0.9;">
          <List>
            <ListItem
              link={"#"}
              popoverClose
              title={lastSelectedTime >= 10
                ? formatMinutes(5)
                : formatMinutes(10)}
              on:click={() =>
                updateCustomTimeSession(lastSelectedTime >= 10 ? 5 : 10)} />
            <ListItem
              link={"#"}
              popoverClose
              title={formatMinutes(15)}
              on:click={() => updateCustomTimeSession(15)} />
            <ListItem
              link={"#"}
              popoverClose
              title={formatMinutes(20)}
              on:click={() => updateCustomTimeSession(20)} />
            <ListItem
              link={"#"}
              popoverClose
              title={formatMinutes(25)}
              on:click={() => updateCustomTimeSession(25)} />
            <ListItem
              link={"#"}
              popoverClose
              title={formatMinutes(30)}
              on:click={() => updateCustomTimeSession(30)} />
            <ListItem>
              <Stepper
                slot="media"
                min={1}
                max={600}
                step={1}
                value={lastSelectedTime}
                small
                onStepperChange={updateCustomTimeSession} />
              <span slot="after">minutes</span>
            </ListItem>
          </List>
        </Popover>
      </Block>
    </div>
    <!-- messages-container -->

    <div class="buttons-container">
      <Block class="tiles" ref="buttons-block">
        <div class="grid grid-cols-3 grid-gap">
          <HomeButton
            on:click={start}
            icon="air"
            text="Relax"
            iconColor="lightblue" />
          <HomeButton
            icon="self_improvement"
            text="Meditate"
            iconColor="deeporange"
            on:click={browseTimer} />
          <HomeButton
            icon="hotel"
            text="Sleep"
            iconColor="primary"
            on:click={start} />
        </div>
      </Block>
    </div>
    <!-- buttons-container -->
  </div>
  <!-- container -->
</Page>

<style>
  h1 {
    font-size: clamp(2em, 4vw, 3em);
    line-height: 1.1;
    font-optical-sizing: auto;
    font-weight: bold;
    user-select: none;
  }

  h2 {
    font-size: clamp(1.5em, 2vw, 2.5em);
    font-optical-sizing: auto;
    font-weight: bold;
    user-select: none;
  }

  .container {
    background-color: rgba(0, 0, 0, 0.5);
  }

  .messages-container {
    position: fixed;
    top: 20%;
    width: 100%;
    z-index: 42;
    user-select: none;
  }

  .buttons-container {
    position: fixed;
    top: 50%;
    width: 100%;
    z-index: 42;
    user-select: none;
  }

  @media (min-width: 768px) {
    .messages-container {
      width: 60%;
      margin-left: 20%;
    }

    .buttons-container {
      width: 80%;
      margin-left: 10%;
    }
  }

  /* svelte trick to ref in css */
  :global([ref="buttons-block"]) {
    /* padding-top: 34%; */
  }

  /* not sure why the Button component needs global here*/
  :global(.button-extra) {
    background-color: rgba(255, 255, 255, 0.5);
  }
</style>
