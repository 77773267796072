<script lang="ts">
  import {
    Fab,
    FabButton,
    FabButtons,
    Icon,
    Page,
    f7
  } from "framework7-svelte";
  import { onDestroy, onMount } from "svelte";
  import SessionFinishPage from "../components/session-finish-page.svelte";
  import {
    CHIMES_1,
    DriftCorrectingTimer,
    MediaPlayer,
    Timer
  } from "../js/media.ts";

  export let f7router;
  export let duration = 5;
  export let bgAudioUrl = null;
  export let endAudioUrl = CHIMES_1;
  export let bgImageUrl = null;
  export let bgVideoUrl = null;
  export let textColor = "white"; // TODO: allow custom text color

  const DEFAULT_TIMER_TEXT = "00:00";
  let timerText = DEFAULT_TIMER_TEXT;
  let timerTextEl: HTMLElement;
  let timerExtraTextEl: HTMLElement;
  let timerExtraText = "";
  let timerElapsedText = "";
  let finishPageEl: SessionFinishPage;
  let videoEl: HTMLVideoElement;
  let timerFinished = false;

  const finishedMediaPlayer = endAudioUrl
    ? new MediaPlayer(endAudioUrl, false)
    : null; // not looping
  const bgMediaPlayer = bgAudioUrl ? new MediaPlayer(bgAudioUrl, true) : null; // looping

  const timer = new Timer("meditation timer", duration * 60 * 1000);

  const onTimerFinished = () => {
    timerFinished = true;
    bgMediaPlayer?.stop();
    finishedMediaPlayer?.start();

    // update display again
    onTimerTick();
    animateAppear(timerExtraTextEl);
  };

  const onTimerTick = () => {
    let text = DEFAULT_TIMER_TEXT;
    if (timer.isFinished()) {
      if (!timerFinished) {
        // update local state
        onTimerFinished(); // call only once
      }
      timerExtraText = timer.getRemainingText();
    } else if (timer.isStarted()) {
      text = timer.getRemainingText();
    }
    timerText = text;

    // updated elapsed
    timerElapsedText = timer.getElapsedText();
  };

  const elapsedTimer = new DriftCorrectingTimer(onTimerTick, 100); // update every 100ms

  onMount(() => {
    timer.start();
    elapsedTimer.start();
    bgMediaPlayer?.start();

    // manual loop implementation to avoid default loop stutter
    // can be null when no video displayed
    videoEl?.addEventListener(
      "ended",
      function () {
        if (!videoEl) return;
        videoEl.currentTime = 0;
        videoEl.play();
      },
      false
    );

    // animate appear timer
    animateAppear(timerTextEl);

    // document.querySelector("video").playbackRate = 0.6;
  });

  const animateAppear = (elem: HTMLElement) => {
    const el = f7.$(elem);
    el.css({ opacity: 0.2 });
    el.animate(
      { opacity: 1 },
      {
        duration: 500,
        easing: "swing"
      }
    );
  };
  const togglePause = () => {
    if (timer.isPaused()) {
      timer.resume();
    } else {
      timer.pause();
    }
  };

  const finish = () => {
    finishPageEl.open();
    // navigateHome(f7router);
  };

  onDestroy(() => {
    elapsedTimer.stop();
    bgMediaPlayer?.stop();
    finishedMediaPlayer?.stop();
  });
</script>

<Page name="timer" class="no-default-bg">
  <div class="background">
    {#if bgVideoUrl}
      <video autoplay muted playsinline bind:this={videoEl}>
        <source src={bgVideoUrl} type="video/mp4" />
        Sorry, there was a problem loading the background animation. please retry
      </video>
    {/if}

    {#if bgImageUrl}
      <div class="bgimage" style="background-image: url({bgImageUrl});"></div>
    {/if}
  </div>

  <div class="container">
    <div class="container-content">
      <h1 bind:this={timerTextEl}>{timerText}</h1>
      <span class="extra-time-text" bind:this={timerExtraTextEl}
        >{timerExtraText}</span>
      <!-- <p>Meditation</p> -->
    </div>

    <Fab position="center-center" onClick={togglePause}>
      <Icon material="pause" />
      <Icon material="play_arrow" />
      <FabButtons position="bottom">
        <FabButton fabClose onClick={finish}
          ><Icon material="stop" /></FabButton>
      </FabButtons>
    </Fab>
  </div>

  <SessionFinishPage
    f7router={f7router}
    bind:this={finishPageEl}
    timeCompletedText={timerElapsedText}
    onContinuePressed={togglePause} />
</Page>

<style>
  :global(.fab > a, .fab-buttons > a) {
    background-color: unset;
  }

  :global(.fab .icon) {
    font-size: 48px;
  }

  :global(.dark .popup.transparent) {
    background: rgb(0, 0, 0, 0.5);
  }

  :global(.fab-buttons .icon) {
    font-size: 36px;
  }

  :global(.fab-center-center) {
    top: 62%;
  }

  video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .container-content {
    position: relative;
    padding-top: 150px;
    color: #fff;
    text-align: center;
  }

  .extra-time-text {
    font-size: clamp(22px, 4vw, 38px);
    user-select: none;
    opacity: 0;
  }

  .container h1 {
    /* font-family:
      Playfair Display,
      serif; */
    font-size: clamp(45px, 7vw, 130px);
    line-height: 1.1;

    font-family: "Rubik", "Roboto", sans-serif;
    font-optical-sizing: auto;
    font-weight: 600;
    font-style: normal;
    user-select: none;
    margin-bottom: 5px;
  }

  .bgimage {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
</style>
