<script lang="ts">
  import { Block, Button, Navbar, Page, Popup } from "framework7-svelte";
  import { onDestroy } from "svelte";
  import { navigateHome } from "..//js/routes";
  import { translate as tr } from "../js/i18n";

  export let f7router;
  export let timeCompletedText = "00:00";
  export let onContinuePressed: null | (() => void) = null;

  let popup: Popup;

  export const open = () => {
    popup.instance().open();
  };

  const onFinish = () => {
    popup.instance().close();
    navigateHome(f7router);
  };

  const onContinue = () => {
    // call client function if any
    if (onContinuePressed) {
      onContinuePressed();
    }

    popup.instance().close();
  };

  // make sure it's closed
  onDestroy(() => popup.instance().close());
</script>

<Popup class="finish-page transparent" swipeToClose bind:this={popup}>
  <Page class="no-default-bg" style={"background-color: rgb(0, 0, 0, 0.5)"}>
    <Navbar title={`You've completed ${timeCompletedText}`}></Navbar>

    <Block>
      <div class="grid grid-cols-2 grid-gap">
        <Button
          fill
          class="button-home"
          iconMaterial="stop"
          textColor="white"
          onClick={onFinish}>{tr("common.finish")}</Button>

        <Button
          fill
          class="button-home"
          iconMaterial="play_arrow"
          textColor="white"
          onClick={onContinue}>{tr("common.continue")}</Button>
      </div>
    </Block>
  </Page>
</Popup>

<style>
  :global(.dark .popup.transparent) {
    background: rgb(0, 0, 0, 0.5);
  }
</style>
